const USERS_MODULE_PREFIX = '/users'
const FRONTEND_MANAGER_MODULE_PREFIX = '/frontend-manager'
const KYC_USERS_MODULE_PREFIX = '/all-users'

export default Object.freeze({
  getHome: () => '/',
  getLogin: () => '/login',
  getUserRoles: () => '/user-roles',
  getReferal: () => '/referal',
  getUserTransaction: () => '/user-transaction',
  getAllUsers: () => `${KYC_USERS_MODULE_PREFIX}`,
  getAllDeletedUsers: () => `/deleted-users`,
  getKycApprovedUsers: () => `${KYC_USERS_MODULE_PREFIX}/kyc-approved-users`,
  getKycPendingUsers: () => `${KYC_USERS_MODULE_PREFIX}/kyc-pending-users`,
  getKycRejectedUsers: () => `${KYC_USERS_MODULE_PREFIX}/kyc-rejected-users`,
  getKycMissingUsers: () => `${KYC_USERS_MODULE_PREFIX}/kyc-missing-users`,
  getEditUser: () => `${KYC_USERS_MODULE_PREFIX}/edit-user-kyc`,
  getPayments: () => '/investments',
  getCreateProperties: () => '/all-properties/create-properties',
  getallProperties: () => '/all-properties',
  getTransactions: () => '/transactions',
  getTreasureHistory: () => '/treasure-history',
  getLeaseRequests: () => '/lease-requests',
  getAmenities: () => '/amenities',
  userMarketplaces: () => '/user-marketplaces',
  getNfts: () => '/nfts',
  getNftsCategories: () => '/nfts-Categories',
  getSFredTypes: () => '/sfred-types',
  getSFredRequests: () => '/sfred-requests',
  getTreasureWallet: () => '/solos-treasury',
  getIssuanceWallet: () => '/issuance-treasury',
  getFredTreasury: () => '/fred-treasury',
  getSfredTreasury: () => '/sfred-treasury',
  getGeneralSettings: () => '/general-settings',
  getFred: () => '/fred',
  getFredSells: () => '/fred-sells',
  getFredRequest: () => '/fred-transactions',
  getFredResale: () => '/fred-resale',
  getUserFredHolding: () => '/user-freds',
  getFredReward: () => '/fred-rewards',
  getFredMaturity: () => '/fred-maturity',
  getSFredResale: () => '/sfred-resale',
  getSFredReward: () => '/sfred-rewards',
  getSfredRequest: () => '/sfred-transactions',
  getUserSfredHolding: () => '/user-sfreds',
  getTvt: () => '/tvt',
  getUserTvt: () => '/user-tvts',
  getTvtTransaction: () => '/tvt-transaction',
  getTvtType: () => '/tvt-types',
  getTvtRequest: () => '/tvt-requests',
  getSuperFred: () => '/super-fred',
  getMintRed: () => '/mint-red',
  getAdminRedTransactions: () => '/admin-red-transactions',
  getAllRedHolders: () => '/all-red-holders',
  getAllRedTransactions: () => '/all-red-transactions',
  getBurnRed: () => '/burn-red',
  getBurnSr: () => '/burn-sr',
  getAdminSrTransactions: () => '/admin-sr-transactions',
  getAllSrTransactions: () => '/all-sr-transactions',
  getAllSrHolders: () => '/all-sr-holders',
  getMintSr: () => '/mint-sr',
  getSrInfo: () => '/sr-info',
  getHistorySr: () => '/history-sr',
  getHistoryRed: () => '/history-red',
  getSendToken: () => '/send-sr',
  getSendRed: () => '/send-red',
  getSupport: () => '/support',
  getTransferTokens: () => '/transfer-token',
  getAllAirdropped: () => '/airdropped',
  getMainNetTransactions: () => '/mainnet-transactions',
  getUsdTransactions: () => '/usd-transaction',
  getBuyFierce: () => '/buy-fierce',
  getFierceUsers: () => '/fierce-users',
  getSellFierce: () => '/sell-fierce',
  getPINSettings: () => '/pin-settings',
  getCommissionSettings: () => '/commission-settings',
  getSettings: () => '/settings',
  getSubscribers: () => '/subscribers',
  getUnSubscribers: () => '/un-subscribers',
  getInvestors: () => '/investors',
  getSupportAllTicket: () => '/support-ticket/all-tickets',
  getSupportPendingTicket: () => '/support-ticket/pending-ticket',
  getSupportClosedTicket: () => '/support-ticket/closed-ticket',
  getSupportOpenedTicket: () => '/support-ticket/opened-ticket',
  getTicketSettings: () => '/support-ticket/ticke-settings',
  getAllPosts: () => '/all-posts',
  getAddNewPost: () => '/add-post',
  getEditPost: () => '/all-posts/edit-post',
  getAllSubAdmins: () => '/all-sub-admins',
  getAddSubAdmin: () => '/add-sub-admin',
  getEditSubAdmin: () => '/all-sub-admins/edit-sub-admins',
  getAllReferEarn: () => '/all-refer-earn',
  getReferEarnSettings: () => '/refer-earn-settings',
  getDashboard: () => '/dashboard',
  // getGeneralSettings: () => '/general-settings',
  getPaymentSettings: () => '/payment-settings',
  getWalletSettings: () => '/wallet-settings',
  getPaymentGateway: () => '/payment-gateway',
  getLogoSettings: () => '/logo-settings',
  getEmailTemplateSettings: () => '/email-template-settings',
  getDomainSettings: () => '/domain-settings',
  getTokenSettings: () => '/token-settings',
  getNewsLetter: () => '/news-letter',
  getSMTPSettings: () => '/smtp-settings',
  getCrowdSale: () => '/crowd-sale',
  getSale: () => '/sale',
  getUserKycs: () => `${USERS_MODULE_PREFIX}`,
  getUserKycEdit: (id) => `${USERS_MODULE_PREFIX}/edit/${id}`,
  getUserKycDetail: (id) => `${USERS_MODULE_PREFIX}/detail/${id}`,
  getFrontendHomeIntro: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/home-intro`,
  getFrontendHomeAbout: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/home/about`,
  getFrontendOurProject: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/home/ourproject`,
  // getFrontendHomeUseCases: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/home/use-cases`,
  getFrontendHomeComponentUseCases: () =>
    `${FRONTEND_MANAGER_MODULE_PREFIX}/home/component-use-cases`,
  getFrontendHomeContentTeam: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/home/content-team`,
  getFrontendHomeSaveChildren: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/home/save-children`,
  getFrontendHomePhotoGallery: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/home/photo-gallery`,
  getFrontendHomeRoadMap: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/home/road-map`,
  getFrontendHomeTeam: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/home/team`,
  getFrontendHomeTeamContent: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/home/team-content`,
  getFrontendHomeTeamFaqs: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/home/faqs`,
  getFrontendHomeSubscribe: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/home/subscribe`,
  getFrontendHomeColorSchema: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/home/color-schema`,
  getFrontendHomeCompany: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/home/company`,
  getFrontendFooter: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/footer-settings`,
  getFrontendLogin: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/login-settings`,
  getFrontendInvest: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/invest`,
  getFrontendTeam: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/team`,
  getTokenomics: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/tokenomics`,
  // getViewTemplate: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/template/view-template`,
  // getAddTemplate: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/template/addTemplate`,
  getFrontendPrivacyPolicy: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/privacy-policy`,
  getFrontendTermOfUse: () => `${FRONTEND_MANAGER_MODULE_PREFIX}/term-of-use`,
})
